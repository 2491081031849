import React, { useEffect, useState } from 'react'
import Button from '../atoms/Button'
import CopyField from '../atoms/CopyField'
import Heading from '../atoms/Heading'
import Stack from '../atoms/Stack'
import Text from '../atoms/Text'
import Notification from '../molecules/Notification'
import imageLoader from '../../lib/imageLoader'
import t, { formatString } from '../../lib/translation'
import globalStyle from '../globals.module.css'
import { alreadyTaken, userRejected } from '../../lib/friendlyErrorMessages'
import TestNFTNotification from '../molecules/TestNFTNotification'
import { truncateEthereumAddress } from '@verisart/shared/src/utils/formatStrings'
import { ConnectorNames } from '@verisart/nft/src/useConnectWallet'
import { Blockchain } from '@verisart/nft/src'
import { ProductionYears, Spinner } from '@verisart/shared'
import { trackShopifyClaimStarted } from '../../lib/mixpanel'

export type VersionType =
  | 'CREATE'
  | 'TRANSFER'
  | 'TRANSFER_INTERNAL'
  | 'EDIT'
  | 'DELETE'

export interface AssetDetails {
  artist: string | null
  productionYears: ProductionYears | null
  thumbnail: string
  title: string
  quantity: number | null
  nftTestnet: boolean
  signedMinting: boolean
  claimed: boolean
  completed?: boolean
  blockchain?: Blockchain
  contractAddress?: string
  tokenIds?: string[]
  firstCertificatePrimaryAssetUrl?: string
  firstCertificateImageUrl?: string
  firstCertificateTitle?: string
}

interface ClaimProps {
  activeWallet?: string
  assetDetails?: AssetDetails
  claimed?: boolean
  claimError?: string
  connectError?: string
  loading?: boolean
  onBack?: () => void
  onClaim?: (isMagicLink: boolean) => void
  onConnect?: () => void
  onConnectMagic?: () => void
  showMagicWallet?: () => Promise<void>
  platform: ConnectorNames | undefined
  isCustodial?: boolean
  showTopBar?: boolean
  disconnect?: () => Promise<void>
  redirectRequiresWallet?: boolean
}

const Claim: React.VFC<ClaimProps> = ({
  activeWallet,
  assetDetails,
  claimed,
  claimError,
  connectError,
  loading,
  onBack,
  onClaim,
  onConnect,
  onConnectMagic,
  showMagicWallet,
  platform,
  isCustodial,
  showTopBar,
  disconnect,
  redirectRequiresWallet,
}) => {
  const [hideActiveWalletDisplay, setHideActiveWalletDisplay] = useState(false)

  const [certificateComplete, setCertificateComplete] = useState(false)
  const [mintComplete, setMintComplete] = useState(false)

  useEffect(() => {
    if (assetDetails?.completed === true) {
      setCertificateComplete(true)
    }
    if (assetDetails?.tokenIds && assetDetails?.tokenIds.length > 0) {
      setMintComplete(true)
    }
  }, [assetDetails])

  const thumbnail =
    certificateComplete && assetDetails?.firstCertificateImageUrl
      ? assetDetails?.firstCertificateImageUrl
      : assetDetails?.thumbnail

  const tokenName =
    certificateComplete && assetDetails?.firstCertificateTitle
      ? assetDetails?.firstCertificateTitle
      : assetDetails?.title

  //successFlag is true when the NFT is in the customers wallet
  const successFlag = isCustodial ? claimed : mintComplete

  return (
    <div
      className={`ver-w-96 ver-gap-[24px] ver-flex ver-flex-col ver-justify-center ver-items-center ver-space-y-5 ${
        (assetDetails?.quantity ?? 0) > 1 ? 'ver-space-y-10' : ''
      }`}
    >
      <div className={'ver-w-full'}>
        <div className={'ver-text-center'}>
          {claimed &&
            (successFlag ? (
              <Heading
                default="heading-1"
                variant="ver-mb-[8px] ver-font-customFontFamilyPrimary ver-text-customFontSizePrimary ver-leading-customLineHeightPrimary ver-tracking-customLetterSpacingPrimary ver-font-customFontWeightPrimary"
              >
                {t.claim.heading.successful}
              </Heading>
            ) : (
              <div className="ver-flex ver-gap-[24px] ver-justify-center ver-items-center">
                <Heading
                  default="heading-1"
                  variant="ver-font-customFontFamilyPrimary ver-text-customFontSizePrimary ver-leading-customLineHeightPrimary ver-tracking-customLetterSpacingPrimary ver-font-customFontWeightPrimary"
                >
                  {t.claim.heading.pending}
                </Heading>
              </div>
            ))}
          {!claimed && (
            <div className="ver-flex ver-flex-col ver-gap-[8px]">
              <Heading
                default="heading-1"
                variant="whitespace-pre-line ver-font-customFontFamilyPrimary ver-text-customFontSizePrimary ver-leading-customLineHeightPrimary ver-tracking-customLetterSpacingPrimary ver-font-customFontWeightPrimary"
              >
                {activeWallet
                  ? // include \\n in the translation to force a line break
                    t.claim.heading.confirm.split('\\n').map((line, i) => (
                      <React.Fragment key={i}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))
                  : t.claim.heading.connect}
              </Heading>
              <Text
                as="p"
                variant="ver-text-subHeading ver-font-customFontFamilySecondary ver-text-customFontSizeSecondary ver-leading-customLineHeightSecondary ver-tracking-customLetterSpacingSecondary ver-font-customFontWeightSecondary"
              >
                {activeWallet
                  ? t.claim.subHeading.confirm
                  : t.claim.subHeading.connect}
              </Text>
            </div>
          )}
        </div>
        {connectError && !activeWallet && (
          <Notification
            title={t.claim.notification.title.connectError}
            body={formatString(connectError)}
            style="error"
            additionalClasses={'ver-mt-5'}
          />
        )}
        {claimError && (
          <Notification
            title={t.claim.notification.title.claimError}
            body={
              alreadyTaken(claimError) ??
              t.claim.notification.body.claimError + ': ' + claimError
            }
            style={userRejected(claimError) ? 'warning' : 'error'}
            additionalClasses={'ver-mt-5'}
          />
        )}
        {!connectError && !claimError && claimed && (
          <div className="ver-text-center ver-text-subHeading">
            <Text variant="ver-font-customFontFamilySecondary ver-text-customFontSizeSecondary ver-leading-customLineHeightSecondary ver-tracking-customLetterSpacingSecondary ver-font-customFontWeightSecondary">
              {successFlag
                ? t.claim.notification.body.successful
                : t.claim.notification.body.pending}
              {platform === ConnectorNames.MagicLink && (
                <span>
                  {' '}
                  To access your wallet, simply{' '}
                  <a
                    href="https://wallet.magic.link/"
                    style={{ textDecoration: 'underline' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    log into Magic
                  </a>{' '}
                  with your email.
                </span>
              )}{' '}
            </Text>
          </div>
        )}
        {assetDetails?.artist && (
          <div className={'ver-w-full ver-mt-[24px] '}>
            <div
              className={`ver-w-full ver-p-[24px] ver-border ver-border-outline ver-flex ver-items-center ver-gap-[24px] ${
                (assetDetails.quantity ?? 0) > 1 ? globalStyle['paper'] : ''
              }`}
            >
              {thumbnail && (
                <div className="ver-w-24 ver-h-24 ver-flex ver-flex-col ver-justify-center ver-items-center">
                  {assetDetails.nftTestnet && <TestNFTNotification size="sm" />}
                  {successFlag && !certificateComplete ? (
                    <Spinner />
                  ) : (
                    <div style={{ backgroundColor: '#EFEFEF', padding: '8px' }}>
                      <img
                        className="ver-bg-outline"
                        alt="Thumbnail"
                        src={imageLoader({
                          src: thumbnail,
                          width: 111,
                        })}
                        width={111}
                        height={111}
                        style={{
                          boxShadow: '3px 3px 6px 0px rgba(0, 0, 0, 0.20)',
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              <Stack className="ver-flex ver-flex-col ver-gap-[8px]">
                <Heading
                  default="heading-2"
                  variant="ver-font-customFontFamilySecondaryBold ver-text-customFontSizeSecondary ver-leading-customLineHeightSecondary ver-tracking-customLetterSpacingSecondary ver-font-customFontWeightSecondaryBold"
                >
                  {tokenName}
                </Heading>
                <Text
                  as="p"
                  variant="ver-font-customFontFamilySecondary ver-text-customFontSizeSecondary ver-leading-customLineHeightSecondary ver-tracking-customLetterSpacingSecondary ver-font-customFontWeightSecondary"
                >
                  {assetDetails.artist}
                </Text>
              </Stack>
            </div>
          </div>
        )}
      </div>
      <div className={'ver-w-full ver-flex ver-flex-col ver-space-y-5'}>
        {activeWallet && !hideActiveWalletDisplay && (
          <div className="ver-w-full ver-p-6 ver-flex ver-flex-col ver-gap-[4px] ver-bg-background ver-mb-[24px]">
            <Text
              as="p"
              variant="ver-text-subHeading ver-font-customFontFamilySecondary ver-text-xs ver-leading-customLineHeightSecondary ver-tracking-customLetterSpacingSecondary ver-font-customFontWeightSecondary"
            >
              {t.claim.walletAddress}
            </Text>
            <CopyField
              value={activeWallet}
              copiedConfirmation={t.utility.copy.copiedTitle}
            >
              <Text
                as="span"
                variant="ver-text-custom ver-font-customFontFamilySecondary ver-text-customFontSizeSecondary ver-leading-customLineHeightSecondary ver-tracking-customLetterSpacingSecondary ver-font-customFontWeightSecondary"
              >
                {truncateEthereumAddress(activeWallet)}
              </Text>
            </CopyField>
          </div>
        )}
        {claimed && (
          <Button
            loading={!successFlag}
            disabledColour
            fullWidth
            onClick={() =>
              redirectRequiresWallet
                ? activeWallet
                  ? onBack?.()
                  : onConnect?.()
                : onBack?.()
            }
            customClassName="hover:ver-bg-buttonHover ver-font-customFontFamilyTertiary ver-text-customFontSizeTertiary ver-leading-customLineHeightTertiary ver-tracking-customLetterSpacingTertiary ver-font-customFontWeightTertiary"
          >
            {t.claim.button.successful}
          </Button>
        )}
        {!claimed && (
          <div className="ver-flex ver-flex-col ver-gap-[16px]">
            {/* as long as there's no magic account connected, we show this original connectWallet button for connect and claim*/}
            {showMagicWallet === undefined && (
              <Button
                testName="connectWallet"
                fullWidth
                loading={loading}
                customClassName="hover:ver-bg-buttonHover ver-font-customFontFamilyTertiary ver-text-customFontSizeTertiary ver-leading-customLineHeightTertiary ver-tracking-customLetterSpacingTertiary ver-font-customFontWeightTertiary"
                onClick={(event) => {
                  event.preventDefault()
                  if (activeWallet) {
                    onClaim?.(false)
                    trackShopifyClaimStarted({ walletType: platform })
                    setHideActiveWalletDisplay(true)
                  } else {
                    onConnect?.()
                  }
                }}
              >
                {activeWallet ? t.claim.button.confirm : t.claim.button.connect}
              </Button>
            )}
            {activeWallet && !showTopBar && (
              <div>
                <span
                  className="ver-flex ver-justify-center ver-items-center ver-cursor-pointer hover:ver-underline ver-font-customFontFamilySecondary ver-text-customFontSizeSecondary ver-leading-customLineHeightSecondary ver-tracking-customLetterSpacingSecondary ver-font-customFontWeightSecondary"
                  onClick={async () => {
                    try {
                      if (disconnect) {
                        await disconnect()
                      }
                    } catch (error) {
                      console.error('Error disconnecting wallet: ', error)
                    }
                  }}
                >
                  Back
                </span>
              </div>
            )}
            <>
              {/* text like button for connect with magic link only shows when we dont have an connected wallet and it's not loading */}
              {!activeWallet && !loading && (
                <button
                  className="ver-flex ver-items-center ver-p-2 ver-w-full"
                  type="button"
                  onClick={async (event) => {
                    event.preventDefault()
                    try {
                      onConnectMagic?.()
                    } catch (e) {
                      // eslint-disable-next-line no-console
                      console.log('Magic connect failed with error: ', e)
                    }
                  }}
                >
                  <span className="ver-flex-grow hover:ver-underline ver-font-customFontFamilySecondary ver-text-customFontSizeSecondary ver-leading-customLineHeightSecondary ver-tracking-customLetterSpacingSecondary ver-font-customFontWeightSecondary">
                    {t.claim.button.connectMagic}
                  </span>
                </button>
              )}
              {/* we show claim with magic button and show Wallet button only when we have Magic account connected, based on if showMagicWallet is undefined */}
              {showMagicWallet !== undefined && (
                <div className="p-2">
                  <Button
                    testName="claimWithMagic"
                    fullWidth
                    loading={loading}
                    onClick={async (event) => {
                      event.preventDefault()
                      if (activeWallet) {
                        onClaim?.(true)
                        trackShopifyClaimStarted({
                          walletType: ConnectorNames.MagicLink,
                        })
                      }
                    }}
                  >
                    {t.claim.button.confirm}
                  </Button>
                  {/* we don't show showWallet button when it's loading */}
                  {!loading && (
                    <button
                      className="ver-flex ver-items-center ver-p-2 ver-w-full"
                      type="button"
                      onClick={async () => {
                        try {
                          await showMagicWallet?.()
                        } catch (error) {
                          console.error(
                            "Couldn't show your wallet information",
                            error
                          )
                        }
                      }}
                    >
                      <span className="ver-flex-grow ver-text-base ver-font-medium hover:ver-underline">
                        {t.claim.button.showMagicWallet}
                      </span>
                    </button>
                  )}
                </div>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  )
}

export default Claim
